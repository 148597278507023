import { Add, East, Edit } from '@mui/icons-material';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import useLocalStorageState from 'use-local-storage-state';
import { API_PRESENTATION } from '../../../api/digital-signage-cms/presentation';
import { PresentationDetails } from '../../../api/digital-signage-cms/types';
import { UserAuthData } from '../../../api/user/types';
import ConfirmDialog from '../../common/ConfirmDialog';
import CopyText from '../../common/CopyText';
import EditPresentation from './components/EditPresentation';
import NewPresentationDialog from './components/NewPresentationDialog';
import PresentationDetailsDialog from './components/PresentationDetails';

type Props = {
  // eslint-disable-next-line
  allPresentations: any[];
  user: UserAuthData;
};

const Presentations = ({ allPresentations, user }: Props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [tutorialStep, setTutorialStep] = useLocalStorageState<number>('tutorial-step');
  const [tutorialInView, setTutorialInView] = useState<boolean>(tutorialStep === 7 ? true : false);
  const [confirmationDialogInView, setConfirmationDialogInView] = useState<boolean>(false);
  const [presentationDialogInView, setPresentationDialogInView] = useState<boolean>(false);
  const [presentationDialogDetailsInView, setPresentationDialogDetailsInView] = useState<boolean>(false);
  const [presentationDialogEditInView, setPresentationDialogEditInView] = useState<boolean>(false);
  const [selectedPresentationIndex, setSelectedPresentationIndex] = useState<number>(-1);

  // const handleDeleteBtnClick = (index: number) => {
  //   setSelectedPresentationIndex(index);
  //   setConfirmationDialogInView(true);
  // };

  const handlePresentationDeletion = async (res: boolean) => {
    if (res) {
      const presentation = allPresentations[selectedPresentationIndex];
      if (presentation && presentation.id) {
        deletePresentationMutation.mutate(presentation);
      }
    }
    setSelectedPresentationIndex(-1);
    setConfirmationDialogInView(false);
  };
  // deleting mutation
  const deletePresentationMutation = useMutation({
    mutationFn: (presentationToDelete: PresentationDetails) =>
      API_PRESENTATION.deletePresentation(presentationToDelete.id ? presentationToDelete.id : ''),
    onMutate: async (presentationToDelete: PresentationDetails) => {
      await queryClient.cancelQueries({ queryKey: ['allClientPresentations'] });
      const previousPresentations = queryClient.getQueryData<PresentationDetails[]>(['allClientPresentations']);
      if (previousPresentations) {
        queryClient.setQueryData<PresentationDetails[]>(
          ['allClientPresentations'],
          (previousPresentations: PresentationDetails[] | undefined) =>
            previousPresentations?.filter((p: PresentationDetails) => p.id !== presentationToDelete.id)
        );
        return { previousPresentations };
      }
    },
    onError: (err, presentationToDelete, context) => {
      if (context?.previousPresentations) {
        queryClient.setQueryData(['allClientPresentations'], context.previousPresentations);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['allClientPresentations'] });
    },
  });
  // new presentation dialog
  const handleNewPresentationDialogClick = () => {
    setPresentationDialogInView(!presentationDialogInView);
    setPresentationDialogDetailsInView(false);
    setPresentationDialogEditInView(false);
  };

  // editing a presentation
  const handlePresentationEditClick = (index: number) => {
    setSelectedPresentationIndex(index);
    setPresentationDialogInView(false);
    setPresentationDialogDetailsInView(false);
    setPresentationDialogEditInView(!presentationDialogEditInView);
  };

  // viewing the presentation details
  const handlePresentationDetailsClick = (index: number) => {
    setSelectedPresentationIndex(index);
    setPresentationDialogEditInView(false);
    setPresentationDialogInView(false);
    setPresentationDialogDetailsInView(true);
  };

  useEffect(() => {
    if (tutorialStep !== -1 && tutorialStep === 8) {
      toast.success(
        'You did it! Now just copy the presentation URL and paste it in your browser of digital signage player of your choice.',
        { duration: 15000 }
      );
      setTutorialStep(-1);
    }
    // eslint-disable-next-line
  }, [tutorialStep]);

  return (
    <Card
      sx={{
        width: '100%',
        padding: 0,
        height: '86vh',
      }}
    >
      <CardHeader
        disableTypography
        title={
          <Grid container>
            <Grid
              item
              xs={9}
              display={'flex'}
              flexDirection={'row'}
              gap={1}
              alignItems={'center'}
              justifyContent={'left'}
            >
              <Typography fontWeight={'bold'}>Presentation</Typography>
              <SmartDisplayIcon fontSize='medium' />
            </Grid>
            <Grid item xs={3} display={'flex'} justifyContent={'center'}>
              {allPresentations.length > 0 ? null : (
                <Button onClick={handleNewPresentationDialogClick} sx={{ backgroundColor: theme.palette.primary.main }}>
                  <Add htmlColor='#fff' />
                </Button>
              )}
            </Grid>
          </Grid>
        }
      />
      <CardContent sx={{ width: '100%', height: '92%', marginTop: '3%' }}>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Name</TableCell>
                <TableCell align='center'>Zones</TableCell>
                <TableCell align='center'>URL</TableCell>
                <TableCell align='center'>Description</TableCell>
                <TableCell align='center'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allPresentations.length > 0 ? (
                allPresentations.map((presentation, index) => {
                  return (
                    <TableRow key={`presentation-${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell
                        padding={'normal'}
                        component='th'
                        onClick={() => handlePresentationDetailsClick(index)}
                      >
                        <Typography>{presentation.name}</Typography>
                      </TableCell>
                      <TableCell padding={'normal'} align='right'>
                        <Typography textAlign={'center'}>{presentation._count.zones}</Typography>
                      </TableCell>
                      <TableCell padding={'normal'} align='right'>
                        <Box display={'flex'} justifyContent={'center'}>
                          <CopyText text={presentation.url} />
                        </Box>
                      </TableCell>
                      <TableCell padding={'normal'} align='right'>
                        <Typography textAlign={'center'} noWrap overflow={'hidden'} textOverflow={'ellipsis'}>
                          {presentation.description}
                        </Typography>
                      </TableCell>
                      <TableCell padding={'normal'}>
                        <Stack flexDirection={'row'} spacing={0} justifyContent={'center'}>
                          <Button style={{ padding: 0 }} onClick={() => handlePresentationEditClick(index)}>
                            <Edit htmlColor='green' />
                          </Button>
                          {/* <Button style={{ padding: 0 }} onClick={() => handleDeleteBtnClick(index)}>
                            <Delete htmlColor='red' />
                          </Button> */}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell>
                    <Typography width={150} fontWeight={'bold'} textAlign={'center'}>
                      No Presentations
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      {presentationDialogInView && <NewPresentationDialog user={user} handleClose={handleNewPresentationDialogClick} />}
      {presentationDialogEditInView && (
        <EditPresentation
          user={user}
          presentationDetails={allPresentations[selectedPresentationIndex]}
          handleClose={() => setPresentationDialogEditInView(false)}
        />
      )}
      {presentationDialogDetailsInView && (
        <PresentationDetailsDialog
          presentationDetails={allPresentations[selectedPresentationIndex]}
          handleClose={() => setPresentationDialogDetailsInView(false)}
        />
      )}
      {confirmationDialogInView && (
        <ConfirmDialog
          title='Please confirm this action'
          message='You are about to delete this presentation'
          handleClick={handlePresentationDeletion}
        />
      )}
      {tutorialInView ? (
        <Box position={'absolute'} bgcolor={'#000000e8'} top={0} height={'100%'} width={'100%'} zIndex={10}>
          <Grid container p={1}>
            <Grid
              item
              xs={9}
              display={'flex'}
              flexDirection={'row'}
              gap={1}
              alignItems={'center'}
              justifyContent={'left'}
            >
              <Typography fontWeight={'bold'} color={'#fff'} fontSize={'1.4rem'}>
                New Presentation
              </Typography>
              <East fontSize='medium' style={{ color: '#fff' }} />
            </Grid>
            <Grid item xs={3} display={'flex'} justifyContent={'center'}>
              <Button sx={{ backgroundColor: theme.palette.primary.main }}>
                <Add htmlColor='#fff' />
              </Button>
            </Grid>
          </Grid>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            p={1}
            gap={3}
            height={'70%'}
          >
            <Typography color={'#fff'} fontSize={'1.8rem'} width={'60%'} textAlign={'center'}>
              Once a Presentation is created all you need to do is copy the URL and paste it in your browser or digital
              signage device of your choice!
            </Typography>
            <Button variant='contained' onClick={() => setTutorialInView(false)}>
              <Typography padding={1} fontWeight={'bold'}>
                Got it!
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Card>
  );
};

export default Presentations;
