import axios from '../../../config/axiosV2';
import { NewPlaylist, PlaylistData } from '../types';

/**
 * This function is used for creating a brand new Playlist
 * @param newPlaylistData - name of the playlist. along with the list of media to attach
 * @param clientId - the logged in user current client
 * @returns boolean - success or fail
 */
const create = async (newPlaylistData: NewPlaylist, clientId: string) => {
  console.log(newPlaylistData);
  try {
    const res = await axios.post('/digitalsignage/playlist', newPlaylistData, {
      headers: {
        ccid: clientId,
      },
    });
    const { data } = res;
    return data;
  } catch (error) {
    return false;
  }
};

/**
 * This function is used for editing a Playlist:
 * Name, Media, and any validity
 * @param {editedPlaylistData: PlaylistData, clientId: string}
 * @returns false if FAILED. Updated Playlist if SUCCESS
 */
const edit = async ({ editedPlaylistData, clientId }: { editedPlaylistData: PlaylistData; clientId: string }) => {
  try {
    const res = await axios.put(`/digitalsignage/playlist/edit/${editedPlaylistData.id}`, editedPlaylistData, {
      headers: {
        ccid: clientId,
      },
    });
    const { data } = res;
    return data;
  } catch {
    return false;
  }
};
/**
 * This function is called when a User visits the URL:
 * /digital-signage/playlist
 * @param clientId - the User current client
 * @returns boolean (failure) or list of all Playlist
 */
const getAllPlaylist = async (clientId: string) => {
  try {
    const res = await axios.get('/digitalsignage/playlist/all', {
      headers: {
        ccid: clientId,
      },
    });
    const { data } = res;
    const { playlists } = data;
    return playlists;
  } catch {
    return false;
  }
};

/**
 * This function is called when a User selects the
 * 'delete' button AND confirms the deletion.
 * @param id
 * @returns false if FAILED/ Data if SUCCESS
 */
const deletePlaylist = async (id: string) => {
  try {
    const res = await axios.post(`/digitalsignage/playlist/delete/${id}`);
    const { data } = res;
    return data;
  } catch {
    return false;
  }
};

/**
 * This function is called when a user selects "edit" for a Playlist
 * in the list of all Playlist.
 */
const getPlaylistById = async (id: string | undefined, clientId: string) => {
  if (id) {
    try {
      const res = await axios.get(`/digitalsignage/playlist/${id}`, {
        headers: {
          ccid: clientId,
        },
      });
      const { data } = res;
      const { playlist } = data;
      return playlist;
    } catch {
      return false;
    }
  }
};

export { create, deletePlaylist, edit, getAllPlaylist, getPlaylistById };
