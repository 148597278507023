import { Add, East } from '@mui/icons-material';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { API_PLAYLIST } from '../../../api/digital-signage-cms/playlist';
import { MediaInPlaylistNew } from '../../../api/digital-signage-cms/types';
import { UserAuthData } from '../../../api/user/types';
import MediaDialog from './components/MediaDialog';
import TableRowData from './components/TableRowData';
import s from './styles.module.scss';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

const AddPlaylist = ({ user }: { user: UserAuthData }) => {
  const theme = useTheme();
  const [tutorialStep, setTutorialStep] = useLocalStorageState<number>('tutorial-step');
  const [tutorialInView, setTutorialInView] = useState<boolean>(tutorialStep === 6 ? true : false);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const mainBlue = theme.palette.primary.main;
  const lightGray = theme.palette.secondary.light;
  const infoGray = theme.palette.info.light;
  const todaysDate = new Date();
  // state data
  const [playlistName, setName] = useState<string>('');
  const [validityStartDate, setValidityStartDate] = useState<string | Date>('');
  const [validityEndDate, setValidityEndDate] = useState<string | Date>('');
  const [finalizedMediaSelected, setFinalizedMediaSelected] = useState<MediaInPlaylistNew[]>([]);
  // input errors stats
  const [playlistNameError, setPlaylistNameError] = useState<{ isError: boolean; message: string }>({
    isError: false,
    message: '',
  });
  const [validityStartDateError, setValidityStartDateError] = useState<{ isError: boolean; message: string }>({
    isError: false,
    message: '',
  });
  const [validityEndDateError, setValidityEndDateError] = useState<{ isError: boolean; message: string }>({
    isError: false,
    message: '',
  });
  // Boolean states
  const [hasValidityDate, setHasValidityDate] = useState<boolean>(false);
  const [mediaDialogInView, setMediaDialogInView] = useState<boolean>(false);
  const [applyToAllBtn, setApplyToAllBtn] = useState<boolean>(false);
  // Creation of the playlist
  // validations will be done before submitting
  const handlePlaylistCreation = async () => {
    let errorCount = 0;
    const _playlistNameError = { isError: false, message: '' };
    const _validityStartDateError = { isError: false, message: '' };
    const _validityEndDateError = { isError: false, message: '' };
    // name validition
    if (playlistName.length < 3) {
      errorCount++;
      _playlistNameError.isError = true;
      _playlistNameError.message = 'Playlist name be greater than 3 characters';
    }
    // check to see first if we have a value for both start and end date
    if (validityStartDate && validityEndDate) {
      const _startDate = new Date(validityStartDate);
      const _endDate = new Date(validityEndDate);
      if (_startDate > _endDate) {
        errorCount++;
        _validityStartDateError.isError = true;
        _validityStartDateError.message = 'Start date must occur before end date';
        _validityEndDateError.isError = true;
        _validityEndDateError.message = 'End date must occur after start date';
      }
    } else {
      // validation date validations
      // if there is an end date then we need to make sure the date has not passed
      if (validityEndDate) {
        const _endDate = new Date(validityEndDate);
        if (_endDate < todaysDate) {
          errorCount++;
          _validityEndDateError.isError = true;
          _validityEndDateError.message = 'End date cannot be in the past.';
        }
      }
    }

    // has not
    if (errorCount == 0) {
      const newPlaylistData = {
        name: playlistName,
        media: finalizedMediaSelected,
      };
      const success = await API_PLAYLIST.create(newPlaylistData, user.currentClient.client.id);
      if (success) {
        toast.success('Playlist created succesfully');
        if (tutorialStep === -1) {
          navigate('/digital-signage/playlist');
        } else {
          setTutorialStep(7);
          navigate('/digital-signage/presentations');
        }
      } else {
        toast.error('Failed to create Playlist.');
      }
    }
    setValidityStartDateError(_validityStartDateError);
    setValidityEndDateError(_validityEndDateError);
    setPlaylistNameError(_playlistNameError);
  };

  /**
   * This function is passed to the child component. The child component will
   * pass the list of media that was selected from the popup.
   * This function will keep an original copy of the media and then set the final
   * media list with the final position and duration of each media
   * @param mediaSelected : list of the media selected
   */
  const updateMediaSelectedFromChild = (mediaSelected: MediaInPlaylistNew[]) => {
    // we need to check to see if there are any validity dates for already selected media
    if (hasValidityDate && finalizedMediaSelected.length > 0) {
      // iterate through new media list and check if they have a validity date
      for (let i = 0; i < mediaSelected.length; i++) {
        const tempMedia = mediaSelected[i];
        const tempMediaIndex = finalizedMediaSelected.findIndex((m) => m.media.id === tempMedia.media.id);
        if (tempMediaIndex > -1) {
          if (finalizedMediaSelected[tempMediaIndex].validityStartDate) {
            tempMedia.validityStartDate = finalizedMediaSelected[tempMediaIndex].validityStartDate;
          }
          if (finalizedMediaSelected[tempMediaIndex].validityEndDate) {
            tempMedia.validityEndDate = finalizedMediaSelected[tempMediaIndex].validityEndDate;
          }
        }
      }
      setFinalizedMediaSelected(mediaSelected);
    } else {
      setFinalizedMediaSelected(mediaSelected);
    }
  };
  // handling attaching the validity dates to a media that
  // has its checkbox selected
  const addValidityDatesToMedia = (index: number, hasDates: boolean) => {
    const _finalizedMediaSelected = [...finalizedMediaSelected];
    if (hasDates) {
      if (validityStartDate) {
        _finalizedMediaSelected[index].validityStartDate = validityStartDate;
      }
      if (validityEndDate) {
        _finalizedMediaSelected[index].validityEndDate = validityEndDate;
      }
    } else {
      _finalizedMediaSelected[index].validityStartDate = '';
      _finalizedMediaSelected[index].validityEndDate = '';
    }
    setFinalizedMediaSelected(_finalizedMediaSelected);
  };

  // handling the duration changes for media
  const handleMediaDurationChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const _finalizedMediaSelected = [...finalizedMediaSelected];
    if (event.target.value.length === 0) {
      _finalizedMediaSelected[index].duration = '';
      setFinalizedMediaSelected(_finalizedMediaSelected);
    } else {
      const parsedValue = parseInt(event.target.value);
      if (!isNaN(parsedValue)) {
        _finalizedMediaSelected[index].duration = parsedValue;
        setFinalizedMediaSelected(_finalizedMediaSelected);
      }
    }
  };

  const handleValidityDateChange = (type: number, value: string) => {
    // if 1 -> end Date
    if (type) {
      if (value) {
        setValidityEndDate(value);
      } else {
        setValidityEndDate('');
      }
    } else {
      if (value) {
        setValidityStartDate(value);
      } else {
        setValidityStartDate('');
        setValidityEndDate('');
      }
    }
  };

  // DRAGGABLE FUNCTIONS
  // saving references for dragItem and dragOverItem
  // eslint-disable-next-line
  const dragItem = useRef<any>(null);
  // eslint-disable-next-line
  const dragOverItem = useRef<any>(null);

  // handling sorting
  const handleSort = () => {
    // duplicate items
    const _playlistSlides = [...finalizedMediaSelected];
    // remove and save the dragged item content
    const draggedItemContent = _playlistSlides.splice(dragItem.current, 1)[0];
    // switch the position
    _playlistSlides.splice(dragOverItem.current, 0, draggedItemContent);
    // reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;
    // update the actual array
    setFinalizedMediaSelected(_playlistSlides);
  };

  const removeAllMediaValidityDates = (mediaList: MediaInPlaylistNew[]) => {
    const _finalizedMediaSelected = [...mediaList].map((media) => {
      const _media = { ...media };
      _media.validityStartDate = '';
      _media.validityEndDate = '';
      return _media;
    });
    return _finalizedMediaSelected;
  };

  // useEffect for the change of validity date. if the validity is false
  // we need to remove the validity date from all media
  useEffect(() => {
    if (!hasValidityDate) {
      const _finalizedMediaSelected = removeAllMediaValidityDates(finalizedMediaSelected);
      setValidityStartDate('');
      setValidityEndDate('');
      setFinalizedMediaSelected(_finalizedMediaSelected);
    }
    // eslint-disable-next-line
  }, [hasValidityDate]);

  // useEffect for the apply validitiy date to all checkbox
  useEffect(() => {
    // apply to all. iterate through the media and add the dates
    if (applyToAllBtn) {
      const _finalizedMediaSelected = finalizedMediaSelected.map((media) => {
        const _media = { ...media };
        _media.validityStartDate = validityStartDate;
        _media.validityEndDate = validityEndDate;
        return _media;
      });
      setFinalizedMediaSelected(_finalizedMediaSelected);
    } else {
      const _finalizedMediaSelected = removeAllMediaValidityDates(finalizedMediaSelected);
      setFinalizedMediaSelected(_finalizedMediaSelected);
    }
    // eslint-disable-next-line
  }, [applyToAllBtn]);

  return (
    <Box
      position={'relative'}
      width={'100%'}
      // maxWidth={{ xs: '310px', sm: '768px', md: '1024px', lg: '1600px' }}
      height={'86vh'}
      overflow={'scroll'}
      bgcolor={'#fff'}
      borderRadius={2}
      sx={{
        '&::-webkit-scrollbar': { display: 'none' },
        overflow: 'hidden',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      <Grid container height={{ xs: '8%', md: '10%' }} bgcolor={lightGray}>
        <Grid item xs={9} display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'} justifyContent={'left'}>
          <Typography fontWeight={'bold'} pl={{ xs: 1, md: 2 }}>
            New Playlist
          </Typography>
          <FeaturedPlayListIcon />
        </Grid>
      </Grid>
      <Stack direction={{ xs: 'column', md: 'row' }}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={{ xs: '100%', md: '30%' }}
          gap={2}
          pt={{ xs: 2, sm: 4, md: 1 }}
          pb={{ xs: 2, sm: 4, md: 0 }}
          pl={{ xs: 1, sm: 14, md: 1 }}
          pr={{ xs: 1, sm: 14, md: 1 }}
          borderBottom={{ xs: `2px solid ${lightGray}`, md: 'none' }}
        >
          <Box display={'flex'} flexDirection={'column'}>
            <FormLabel>Name</FormLabel>
            <TextField
              value={playlistName}
              onChange={(event) => setName(event.target.value)}
              error={playlistNameError.isError}
              helperText={playlistNameError.message}
              fullWidth
            />
          </Box>
          <Grid container>
            <Grid item xs={8}>
              <FormLabel>Media</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <Button sx={{ backgroundColor: mainBlue }} onClick={() => setMediaDialogInView(true)}>
                <Add htmlColor='#fff' />
              </Button>
            </Grid>
          </Grid>
          <Box>
            <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <FormControlLabel
                control={<Checkbox checked={hasValidityDate} onChange={() => setHasValidityDate(!hasValidityDate)} />}
                label='Include validity date'
              />
              <Tooltip title='Validity date is applied to each individual selected image'>
                <InfoIcon htmlColor={mainBlue} fontSize='small' />
              </Tooltip>
            </FormGroup>
          </Box>
          {hasValidityDate && (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Typography color={infoGray}>Select the media to apply validity date</Typography>
              <Grid container alignItems={'center'} justifyContent={'center'} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id={'validity-start-date'}
                    fullWidth
                    type={'date'}
                    value={validityStartDate}
                    error={validityStartDateError.isError}
                    helperText={validityStartDateError.isError ? validityStartDateError.message : 'Start Date'}
                    inputProps={{ min: todaysDate.toISOString().slice(0, 10) }}
                    onChange={(e) => handleValidityDateChange(0, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id={'validity-end-date'}
                    fullWidth
                    type={'date'}
                    value={validityEndDate}
                    error={validityEndDateError.isError}
                    helperText={validityEndDateError.isError ? validityEndDateError.message : 'End Date'}
                    inputProps={{ min: todaysDate.toISOString().slice(0, 10) }}
                    onChange={(e) => handleValidityDateChange(1, e.target.value)}
                  />
                </Grid>
                <Grid item xs={10} ml={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={applyToAllBtn} onChange={() => setApplyToAllBtn(!applyToAllBtn)} />}
                      label='Apply validity dates to all'
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          )}
          <Button variant='contained' color='error' onClick={() => navigate('/digital-signage/playlist')}>
            Cancel
          </Button>
        </Box>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column' }}
          width={{ xs: '100%', md: '70%' }}
          pt={2}
          gap={2}
          pb={2}
          pr={1}
        >
          {matches ? (
            <TableContainer
              component={Paper}
              style={{ height: '65vh' }}
              sx={{
                '&::-webkit-scrollbar': { display: 'none' },
                overflow: 'scroll',
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
              }}
            >
              <Table sx={{ minWidth: { md: 600 } }} aria-label='media selected table'>
                <TableHead>
                  <TableRow style={{ backgroundColor: mainBlue }}>
                    {hasValidityDate ? (
                      <TableCell align='center' width={'10%'}>
                        <Typography color={'#fff'}>Apply Validity Date</Typography>
                      </TableCell>
                    ) : (
                      ''
                    )}
                    <TableCell align='center' width={'5%'}>
                      <Typography color={'#fff'}>Position</Typography>
                    </TableCell>
                    <TableCell align='center' width={'35%'}>
                      <Typography color={'#fff'}>Preview</Typography>
                    </TableCell>
                    <TableCell align='center' width={'25%'}>
                      <Typography color={'#fff'}>Title</Typography>
                    </TableCell>
                    <TableCell align='center' width={'30%'}>
                      <Typography color={'#fff'}>Duration (secs)</Typography>
                    </TableCell>
                    {hasValidityDate ? (
                      <>
                        <TableCell align='center' width={'15%'}>
                          <Typography color={'#fff'}>Start Date</Typography>
                        </TableCell>
                        <TableCell align='center' width={'15%'}>
                          <Typography color={'#fff'}>End Date</Typography>
                        </TableCell>
                      </>
                    ) : (
                      ''
                    )}
                    <TableCell align='center' width={'5%'}>
                      <Typography color={'#fff'}>Actions</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    '&::-webkit-scrollbar': { display: 'none' },
                    overflow: 'scroll',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                  }}
                >
                  {finalizedMediaSelected.length > 0 ? (
                    finalizedMediaSelected.map((media: MediaInPlaylistNew, index: number) => {
                      return (
                        <TableRow
                          key={`media-${index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          draggable
                          onDragStart={() => (dragItem.current = index)}
                          onDragEnter={() => (dragOverItem.current = index)}
                          onDragEnd={handleSort}
                          onDragOver={(e) => e.preventDefault()}
                          className={s.grabbable}
                        >
                          <TableRowData
                            includeValidityDate={hasValidityDate}
                            index={index}
                            mediaDetails={media}
                            addValidityDates={addValidityDatesToMedia}
                            handleMediaDurationChange={handleMediaDurationChange}
                          />
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell width={'15%'}></TableCell>
                      <TableCell width={'15%'}></TableCell>
                      <TableCell width={'40%'}>
                        <Typography textAlign={'center'}>No currently selected media</Typography>
                      </TableCell>
                      <TableCell width={'15%'}></TableCell>
                      <TableCell width={'15%'}></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography textAlign={'left'} pt={1} pb={1}>
              No Currently Selected Media
            </Typography>
          )}
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2} width={'100%'} pt={1} pb={1}>
            <Button sx={{ backgroundColor: theme.palette.primary.main }} onClick={handlePlaylistCreation}>
              <Typography color={'#fff'}>Create</Typography>
            </Button>
          </Box>
        </Box>
      </Stack>
      {mediaDialogInView && (
        <MediaDialog
          handleClose={() => setMediaDialogInView(false)}
          updateSelectedMedia={updateMediaSelectedFromChild}
          playlistSlides={finalizedMediaSelected}
        />
      )}
      {/* Tutorial Step Setup */}
      {tutorialInView ? (
        <Box position={'absolute'} bgcolor={'#000000e8'} top={0} height={'100%'} width={'100%'} zIndex={10}>
          <Stack direction={{ xs: 'column', md: 'row' }} height={'100%'}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              width={{ xs: '100%', md: '30%' }}
              gap={2}
              pt={{ xs: '6vh', md: '10vh' }}
              pb={{ xs: 2, sm: 4, md: 0 }}
              pl={{ xs: 1, sm: 14, md: 1 }}
              pr={{ xs: 1, sm: 14, md: 1 }}
              borderBottom={{ xs: `2px solid ${lightGray}`, md: 'none' }}
            >
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Typography width={'100%'} color={'#fff'} fontWeight={'bold'} textAlign={'left'} fontSize={'1.2rem'}>
                  Give Your Playlist A Name
                </Typography>
                <Box height={'6vh'} width={'100%'} border={'1px solid #fff'} borderRadius={1}></Box>
              </Box>
              <Grid container>
                <Grid item xs={8} display={'flex'} alignItems={'center'}>
                  <Typography color={'#fff'} mr={1} fontSize={'1.2rem'}>
                    Add Media
                  </Typography>
                  <East htmlColor='#fff' />
                </Grid>
                <Grid item xs={4}>
                  <Button sx={{ backgroundColor: mainBlue }}>
                    <Add htmlColor='#fff' />
                  </Button>
                </Grid>
              </Grid>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'} height={'4vh'}>
                <Checkbox defaultChecked={true} style={{ color: '#fff', padding: 0, marginRight: '1vw' }} />
                <Typography color={'#fff'} fontSize={'1.2rem'}>
                  Control When A Media is Shown
                </Typography>
              </Box>
            </Box>
            <Box
              display={'flex'}
              flexDirection={{ xs: 'column' }}
              width={{ xs: '100%', md: '70%' }}
              pt={{ xs: '6vh', md: '10vh' }}
              gap={2}
              pb={2}
              borderLeft={'2px solid #fff'}
            >
              <Typography textAlign={'center'} pt={1} pb={1} color={'#fff'} fontSize={'1.4rem'} fontWeight={'bold'}>
                All Media connected to the playlist will appear here. The following can be done with each Media.
              </Typography>
              <Box height={'77%'} width={'95%'} border={'2px solid #fff'} borderRadius={3} ml={'2.5%'}>
                <List>
                  <ListItem>
                    <Typography color={'#fff'} fontSize={'1.4rem'}>
                      1. Drag & Drop to reorder
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography color={'#fff'} fontSize={'1.4rem'}>
                      2. Media Duration. The time a media appears on the screen.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography color={'#fff'} fontSize={'1.4rem'}>
                      3. Remove Media from playlist
                    </Typography>
                  </ListItem>
                </List>
              </Box>
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'} pt={1} pb={1}>
                <Button sx={{ backgroundColor: theme.palette.primary.main }} onClick={() => setTutorialInView(false)}>
                  <Typography color={'#fff'}>Got it!</Typography>
                </Button>
              </Box>
            </Box>
          </Stack>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AddPlaylist;
